// @TODO: Export and import other hooks here
import { useMediaQuery } from "react-responsive";

enum Breakpoints {
  xs = 374,
  sm = 480,
  md = 768,
  lg = 1170,
  xl = 1440,
}

export function useIsSmallScreen() {
  return useMediaQuery({
    minWidth: Breakpoints.sm,
    maxWidth: Breakpoints.md - 1,
  });
}

export function useIsLargeScreen() {
  return useMediaQuery({
    minWidth: Breakpoints.lg + 1,
  });
}
