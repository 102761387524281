import { useIsSmallScreen } from "hooks/index";
import RadioInputChecked from "app/assets/svg/radio-input-checked.svg";
import RadioInputUnchecked from "app/assets/svg/radio-input-unchecked.svg";
import React from "react";
import { BillingPeriodUnit, Plan } from "services/graphql";
import { centsToDollars } from "helpers/centsToDollars";
import { AbbreviatedPeriodUnits } from "constants/cart";
import Button from "app/components/Button";
import { BenefitsText } from "../PlanSelect/BenefitsText";
import styles from "./styles.module.scss";

interface Props {
  isToggled: boolean;
  onClick?(): void;
  plan: Plan;
  toggledText?: string;
}

export function PlanCard({
  isToggled,
  onClick = () => {},
  plan,
  toggledText = "Selected",
}: Props) {
  const isSmallScreen = useIsSmallScreen();
  const abbreviatedPeriodUnit =
    AbbreviatedPeriodUnits[
      plan?.periodUnit as keyof typeof AbbreviatedPeriodUnits
    ];
  return (
    <div
      key={plan.id}
      className={
        isToggled ? styles.toggledPlanCardContainer : styles.planCardContainer
      }
      onClick={() => isSmallScreen && onClick()}
    >
      <div className={styles.planCardHeaderContainer}>
        <header>{plan.accessType}</header>
        {isSmallScreen &&
          (isToggled ? <RadioInputChecked /> : <RadioInputUnchecked />)}
      </div>
      <div className={styles.planCardSubContainer}>
        <div>
          <div className={styles.planCardCostContainer}>
            <h2>${centsToDollars(plan.monthlyCostInCents)}/mo</h2>
            {plan?.periodUnit !== BillingPeriodUnit.Month && (
              <p>
                (Billed ${centsToDollars(plan.priceInCents)}/
                {abbreviatedPeriodUnit})
              </p>
            )}
          </div>
          <BenefitsText plan={plan} />
        </div>
        {!isSmallScreen && (
          <Button
            onClick={() => onClick()}
            disabled={isToggled}
            className={styles.selectButton}
          >
            {isToggled ? toggledText : "Select"}
          </Button>
        )}
      </div>
    </div>
  );
}
